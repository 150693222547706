@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100vw;
  font-family: var(--font-family) !important;
  color: var(--text-color) !important;
}

* {
  box-sizing: border-box !important;
}

:root {
  --text-color: #262626 !important;
  --font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif !important;
}

.swal2-container, .swal2-container *, .MuiModal-root, .MuiModal-root * {
  z-index: 3649 !important;
  touch-action: auto;
}

.ReactModalPortal > div {
  z-index: 364668 !important;
}

.Toastify, .Toastify * {
  z-index: 364668 !important;
}

.p-sidebar-mask {
  z-index: 364668 !important;
}

html, body, body * {
  -webkit-tap-highlight-color: #0000;
}

#next, #next * {
  touch-action: none;
}

html, body {
  height: 100%;
  overflow: hidden;
}

#__next {
  height: 100%;
  background-color: #000000;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.MuiTooltip-popper {
  z-index: 3699;
}

.MuiTypography-noWrap {
  width: 100%;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

@media only screen and (max-width: 444px) {
  .scrollbarhidden::-webkit-scrollbar {
    display: none;
  }
}

.대충스크롤바::before {
  content: '';
  width: 30px;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 999;
  transition: all .2s;
  opacity: 0;
  left: 123px;
  background: linear-gradient(to right, #fff 0%, #ffffffb3 40%, #fff0 70%);
}

.대충스크롤바.left::before {
  opacity: 1;
}

#___tosspayments_dimmer___ {
  z-index: 36999 !important;
}

.notice-detail-container * {
  all: revert;
}

.notice-detail-container a {
  color: #1890ff !important;
}

.notice-detail-container img {
  width: 100%;
}

#ch-plugin {
  position: static !important;
}
